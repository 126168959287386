<template>
	<div id="personal-info" style="align-items: center;">
		<hr class="thicker-radius" />
		<legend>{{ languageStrings.removeRestrictions }}</legend>
		<p style="text-align: center">{{ languageStrings.fieldsMayBeRequired }}</p>
		<!-- <pre>{{ JSON.stringify(personalInfo, null, "\t") }}</pre> -->
		<!-- <label for=""></label>
			<input id="" type="text" :placeholder="" v-model="" /> -->
		<label for="tax-id">{{ languageStrings.taxId }}</label>
		<input id="tax-id" type="text" :placeholder="languageStrings.taxId" v-model="taxId" />
		<label for="national-id">{{ languageStrings.nationalId }}</label>
		<input id="national-id" type="text" :placeholder="languageStrings.nationalId" v-model="nationalId" />
		<label for="address-number">{{ languageStrings.addressNumber }}</label>
		<input id="address-number" type="text" :placeholder="languageStrings.addressNumber" v-model="addressNumber" />
		<label for="address-suite">{{ languageStrings.addressSuite }}</label>
		<input id="address-suite" type="text" :placeholder="languageStrings.addressSuite" v-model="addressSuite" />
		<label for="address-neighborhood">{{ languageStrings.addressNeighborhood }}</label>
		<input
			id="address-neighborhood"
			type="text"
			:placeholder="languageStrings.addressNeighborhood"
			v-model="addressNeighborhood"
		/>
		<label for="address-city">{{ languageStrings.addressCity }}</label>
		<input id="address-city" type="text" :placeholder="languageStrings.addressCity" v-model="addressCity" />
		<label for="address-addressState">{{ languageStrings.addressState }}</label>
		<input
			id="address-addressState"
			type="text"
			:placeholder="languageStrings.addressState"
			v-model="addressState"
		/>
		<button class="btn" @click="setPersonalInfo()">{{ languageStrings.saveDetailedInfo }}</button>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import { onBeforeUnmount } from "vue";
import router from "@/router";

export default {
	name: "PersonalInfoBasicTransactions",
	props: {
		playerState: Object,
		userProfile: Object,
		languageStrings: Object,
		languageErrorString: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			personalInfo: this?.userProfile?.personalInfo,
			emailAddress: this?.userProfile?.personalInfo?.emailAddress,
			taxId: this?.userProfile?.personalInfo?.taxId,
			nationalId: this?.userProfile?.personalInfo?.nationalId,
			addressNumber: this?.userProfile?.personalInfo?.addressNumber,
			addressStreet: this?.userProfile?.personalInfo?.addressStreet,
			addressSuite: this?.userProfile?.personalInfo?.addressSuite,
			addressNeighborhood: this?.userProfile?.personalInfo?.addressNeighborhood,
			addressCity: this?.userProfile?.personalInfo?.addressCity,
			addressState: this?.userProfile?.personalInfo?.addressState,
		};
	},
	watch: {},
	created() {
		// this.populateFields();
	},
	mounted() {},
	methods: {
		async setPersonalInfo() {
			this.serverBusy = true;
			this.busyText = "Updating Personal Info";

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let body = {
				userId: this.playerState.userId,
				userProfile: {
					personalInfo: {
						personalInfo: this?.personalInfo,
						emailAddress: this?.emailAddress,
						taxId: this?.taxId,
						nationalId: this?.nationalId,
						addressNumber: this?.addressNumber,
						addressStreet: this?.addressStreet,
						addressSuite: this?.addressSuite,
						addressNeighborhood: this?.addressNeighborhood,
						addressCity: this?.addressCity,
						addressState: this?.addressState,
					},
				},
				requiredValidations: "Minimal, BasicTransactions, ConspicuousTransactions",
			};

			let requestUrl = new URL("/api/v1/user/profile", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request).catch(() => {
					this.status.ok = false;
					this.status.message = this.languageErrorStrings.somethingWentWrongTryLater;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				});

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (dataJson?.status === "FailedPersonalInfoValidation") {
					dataJson.validationErrors.forEach((err) => {
						this.status.ok = false;
						this.status.message = `${this.camelToTitleCase(err.field)}: ${this.camelToTitleCase(
							err.reason
						)}`;
						this.eventBus.emit("updateStatus", this.status);
					});
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				if (dataJson?.status !== "Success") {
					this.status.ok = false;
					this.status.message = dataJson.status;
					this.eventBus.emit("updateStatus", this.status);
					this.serverBusy = false;
					this.busyText = "";
					return false;
				}

				let newPlayerState = this.playerState;

				newPlayerState.userProfile = dataJson.userProfile;
				this.personalInfo = dataJson?.userProfile?.personalInfo;

				this.status.ok = true;
				this.status.message = "Profile Updated";
				this.eventBus.emit("updatePlayerState", newPlayerState);
				this.eventBus.emit("updateStatus", this.status);
				this.eventBus.emit("updateUserProfile");
				this.populateFields();
				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
				this.serverBusy = false;
				this.busyText = "";
			}
		},
	},
	// populateFields() {
	// 	this.nameFirst = this?.personalInfo?.nameFirst;
	// 	this.nameLast = this?.personalInfo?.nameLast;
	// 	this.nameLast2 = this?.personalInfo?.nameLast2;
	// 	this.birthDate = new Date(this?.personalInfo?.birthDate).toISOString().split("T")[0];
	// 	this.nationality = this?.personalInfo?.nationality;
	// 	this.emailAddress = this?.personalInfo?.emailAddress;
	// 	this.emailVerificationStatus = this?.personalInfo?.emailVerificationStatus;
	// 	this.addressCountry = this?.personalInfo?.addressCountry;
	// 	this.addressCounty = this?.personalInfo?.addressCounty;
	// 	this.addressZipCode = this?.personalInfo?.addressZipCode;
	// },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#personal-info {
	display: flex;
	flex-flow: column;
}

.thicker-radius {
	width: 100%;
}

input {
	width: 50%;
	border-radius: 15px;
	height: 2.8vh;
	color: #6305C9;
}

</style>
