<template>
	<div>
		<div :id=" mainNavIsHidden ? 'main-nav-hidden-profile-container': 'profile-container'" class="encore-background">
			<fieldset>
				<legend>
					{{ languageStrings.accountProfileFor }}
					<span v-if="playerState?.displayName?.length > 0"> {{ playerState.displayName }}</span>
				</legend>
				<p v-if="playerState.permissionsSuspended" class="suspended">
					{{ languageStrings.suspendedMessage }}
				</p>
				<ChangeDisplayName
					:playerState="playerState"
					:userProfile="userProfile"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
				<ChangeUserEmail
					:playerState="playerState"
					:userProfile="userProfile"
					:systemSettings="systemSettings"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
				<VerifyUserPhone
					v-if="!playerState?.isUserVerified"
					:playerState="playerState"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
				<PersonalInfoBasicTransactions
					v-if="countryCode === 'MX' && !playerState?.morePersonalInfoNeeded"
					:playerState="playerState"
					:userProfile="userProfile"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
				<PersonalInfoConspicuousTransactions
					v-if="countryCode === 'MX' && playerState?.morePersonalInfoNeeded"
					:playerState="playerState"
					:userProfile="userProfile"
					:languageStrings="languageStrings"
					:languageErrorStrings="languageErrorStrings"
				/>
			</fieldset>
		</div>
	</div>
</template>

<script>
import ChangeDisplayName from "@/components/ChangeDisplayName";
import VerifyUserPhone from "@/components/VerifyUserPhone";
import ChangeUserEmail from "@/components/ChangeUserEmail";
import PersonalInfoBasicTransactions from "@/components/PersonalInfoBasicTransactions";
import PersonalInfoConspicuousTransactions from "@/components/PersonalInfoConspicuousTransactions";

export default {
	name: "UserProfile",
	components: {
		ChangeDisplayName,
		ChangeUserEmail,
		VerifyUserPhone,
		PersonalInfoBasicTransactions,
		PersonalInfoConspicuousTransactions,
	},
	props: {
		systemSettings: Object,
		playerState: Object,
		isMobile: Boolean,
		verificationCode: String,
		userProfile: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		webPayOptions: Object,
		mainNavIsHidden: Boolean,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#profile-container {
	position: relative;
	top: 30px;
	display: flex;
	flex-direction: column;
	width: 75%;
	margin: auto auto 130px;
	padding-bottom: 100px;
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
	/* box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%),
		inset -2px -2px 10px 2px rgb(0 0 0 / 30%); */
}

#main-nav-hidden-profile-container {
	top: 15vh;
	position: relative;
	display: flex;
	flex-direction: column;
	width: 75%;
	margin: auto auto 130px;
	padding-bottom: 100px;
	background-color: #24272c;
	padding: 30px;
	border-radius: 8px;
}

#main-nav-hidden {
	height: 150vh;
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	#profile-container {
		margin-bottom: 200px;
	}
}
</style>
